div.header--inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    nav.header-menu {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 500;
        border-bottom: 1px solid var(--color--);
        grid-column: large-end;

        .mobile-menu {
            display: block;

            @media (--media--md) {
                display: none;
            }
        }

        .nav-list {
            display: none;
            flex-direction: row;
            justify-content: flex-end;
            align-items: right;
            width: 100vw;
            padding: var(--spacing--md) 0;
            grid-column: large-end;

            @media (--media--md) {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 0;
                padding-bottom: 0;
                margin-top: (calc(50px + var(--spacing--sm)));
            }

            &.active {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                position: absolute;
                top: 105px;
                right: 0;
                background: var(--color--gray);
                border-bottom: 4px solid var(--color--line);
                text-align: right;
            }

            li {
                list-style-type: none;
                width: fit-content;
                padding: 0 var(--spacing--sm);

                &.current {
                    pointer-events: none;

                    .circlebutton {
                        transform: scale(1.1);
                        transition: all 0.25s var(--easing--in-out);
                    }

                    a {
                        font-weight: 500;
                        text-decoration: none;
                        color: var(--color--font-base);
                    }
                }

                a {
                    display: flex;
                    flex-direction: row;
                    justify-content: right;
                    width: auto;
                    font-size: var(--headline--md);
                    font-weight: 300;
                    text-decoration: none;
                    color: var(--color--primary);
                    margin: var(--spacing--xs) 0;
                    border-radius: 100px;
                    transition: all 0.15s var(--easing--in-out);

                    @media (--media--md) {
                        font-size: var(--text--base);
                        font-weight: 300;
                        margin: var(--spacing--sm) 0;
                    }
                }

                &:last-child {
                    margin: 0;
                    margin-top: var(--spacing--sm);

                    @media (--media--md) {
                        display: flex;
                        align-items: center;
                        padding-right: 0;
                        margin-top: 0;
                    }

                    &.current {
                        a {
                            opacity: 1;
                            font-size: var(--text--xl);
                            color: var(--color--light);
                            background-color: var(--color--primary);
                            border: 1px solid var(--color--primary);

                            @media (--media--md) {
                                font-size: var(--text--base);
                            }
                        }
                    }

                    a {
                        font-size: var(--text--xl);
                        font-weight: 300;
                        color: var(--color--font-base);
                        padding: 1rem 2rem;
                        margin: 0;
                        border: 1px solid var(--color--font-base);
                        border-radius: 30px;

                        @media (--media--md) {
                            font-size: var(--text--base);
                        }

                        @media (hover: hover) {
                            &:hover {
                                opacity: 1;
                                color: var(--color--light);
                                background-color: var(--color--primary);
                                border: 1px solid var(--color--primary);
                            }
                        }
                    }
                }
            }
        }
    }
}
