nav.tablabels {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    background: var(--color--gray);
    padding: 5px;
    border-radius: 10px;

    @media (--media--md) {
        flex-direction: row;
    }

    button {
        display: block;
        min-width: 49%;
        min-height: 100%;
        padding: var(--spacing--sm);
        border-radius: 8px;
        text-align: center;

        &.active {
            background: var(--color--light);
        }
    }
}
