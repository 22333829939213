/* prettier-ignore */

.grid--container {
    display: grid;
        grid-template-columns:
            [space-start]
            var(--spacing--sm)
                [page-start large-start medium-start small-start]
                5fr
                                [center-start]
                                1fr
                                    [center]
                                1fr
                                [center-end]
                5fr
                [page-end large-end medium-end small-end]
            var(--spacing--sm)
            [space-end];

    @media (--media--sm) {
        grid-template-columns:
            [space-start]
            var(--spacing--sm)
                [page-start large-start medium-start small-start]
                5fr
                                [center-start]
                                1fr
                                    [center]
                                1fr
                                [center-end]
                5fr
                [page-end large-end medium-end small-end]
            var(--spacing--sm)
            [space-end];
    }

    @media (--media--md) {
        grid-template-columns:
            [space-start]
            var(--spacing--lg)
                    [large-start]
                    3fr
                        [medium-start]
                        5fr
                            [small-start]
                            7fr
                                [center-start]
                                var(--spacing--xl)
                                    [center]
                                var(--spacing--xl)
                                [center-end]
                            7fr
                            [small-end]
                        5fr
                        [medium-end]
                    3fr
                    [large-end]
            var(--spacing--lg)
            [space-end];
    }

    @media (--media--lg) {
        grid-template-columns:
            [space-start]
            var(--spacing--lg)
                    [large-start]
                    3fr
                        [medium-start]
                        5fr
                            [small-start]
                            7fr
                                [center-start]
                                var(--spacing--xl)
                                    [center]
                                var(--spacing--xl)
                                [center-end]
                            7fr
                            [small-end]
                        5fr
                        [medium-end]
                    3fr
                    [large-end]
            var(--spacing--lg)
            [space-end];
    }

    @media (--media--xl) {
        grid-template-columns:
            [space-start]
            var(--spacing--lg)
                    [large-start]
                    3fr
                        [medium-start]
                        5fr
                            [small-start]
                            7fr
                                [center-start]
                                var(--spacing--xl)
                                    [center]
                                var(--spacing--xl)
                                [center-end]
                            7fr
                            [small-end]
                        5fr
                        [medium-end]
                    3fr
                    [large-end]
            var(--spacing--lg)
            [space-end];
    }

    .grid-area-\[page\] {
        grid-column: page;
    }
    .grid-area-\[page-inner\] {
        grid-column: page-inner;
    }
    .grid-area-\[large\] {
        grid-column: large;
    }
    .grid-area-\[large-inner\] {
        grid-column: large-inner;
    }
    .grid-area-\[medium\] {
        grid-column: medium;
    }
    .grid-area-\[mediumlarge\] {
        grid-column: mediumlarge;
    }
    .grid-area-\[medium-inner\] {
        grid-column: medium-inner;
    }
    .grid-area-\[small\] {
        grid-column: small;
    }
    .grid-area-\[small-inner\] {
        grid-column: small-inner;
    }
}
