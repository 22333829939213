/* background-colors */
.bg--primary {
    background: var(--color--primary);
}
.bg--light {
    background: var(--color--light);
}
.bg--dark {
    background: var(--color--dark);
}
.bg--gray {
    background: var(--color--gray);
}

/* background-colors */
.fc--primary {
    color: var(--color--primary);
}
.fc--secondary {
    color: var(--color--secondary);
}
.fc--third {
    color: var(--color--third);
}
.fc--light {
    color: var(--color--light);
}
.fc--dark {
    color: var(--color--dark);
}
.fc--font-base {
    color: var(--color--font-base);
}
.fc--font-headline {
    color: var(--color--font-headline);
}
