.textmedia {
    display: flex;
    align-items: center;
    z-index: 1;
    text-align: right;

    &.slim {
        .media-content {
            grid-column: space-start / space-end;

            @media (--media--md) {
                grid-column: span 6 / space-end;
            }
        }
    }

    .media-content {
        width: 100%;
        height: 500px;
        z-index: 0;
        overflow: hidden;
        grid-column: space-start / space-end;

        @media (--media--md) {
            grid-column: span 5 / space-end;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .text-content {
        width: 100%;
        position: relative;
        z-index: 1;
        color: var(--color--font-base);
        padding: var(--spacing--xl) 0;
        grid-column: large;
        text-align: left;
        grid-column: page-start / page-end;

        @media (--media--md) {
            padding-right: var(--spacing--md);
            grid-column: medium-start / center-start;
        }

        .lead {
            font-weight: 300;
        }
        .headline {
            font-weight: 600;
        }
        .text {
            font-weight: 300;
        }
    }
}
