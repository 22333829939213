.gdpr-cookieconsent-container {
    --cookieconsent-theme-color: var(--color--primary);
    --cookieconsent-overlay-color: rgb(0 0 0 / 0.5);
    --cookieconsent-bg-color: white;

    --cookieconsent-button-secondary-text-color: var(--color--font-base);
    --cookieconsent-button-borderradius: 0;

    --cookieconsent-spacing: 1rem;
    --cookieconsent-width: 600px;

    --cookieconsent-focus-outline: transparent;
    --cookieconsent-focus-outline-offset: 0;

    flex-direction: row !important;
    justify-content: center;
    max-width: 100vw;
    max-height: 100vh;
    background: rgb(0 0 0 / 0.5);
    overflow: hidden;
    backdrop-filter: blur(10px);

    &::before {
        display: none;
    }

    &:focus-visible {
        outline: transparent;
    }

    .gdpr-cookieconsent-settings {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        overflow-y: scroll;
        border-radius: 25px;
        transform: translate(-50%, -50%);

        @media (max-width: 500px) {
            width: 90vw;
            height: 80vh;
            position: absolute;
        }

        .hidden {
            display: none !important;
        }

        .gdpr-cookieconsent-settings__content {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto 3fr 4fr;
            height: 100%;
            padding: calc(var(--spacing--sm));
            padding-top: var(--spacing--sm);

            @media (--media--md) {
                display: flex;
                flex-direction: column;
                padding: calc(var(--spacing--md) * 1.5);
            }

            .gdpr-cookieconsent-settings__content__info {
                display: block;
                order: 1;
                font-family: var(--font--base-family);
                hyphens: auto;
                padding-bottom: var(--spacing--sm);
                overflow: hidden;

                h2 {
                    font-size: var(--headline--lg);
                    color: var(--color--primary);
                    text-align: center;
                }

                .gdpr-cookieconsent-settings__content__info__description {
                    display: block;
                    font-size: var(--text--base);
                    line-height: var(--text--base-height);
                    margin-bottom: var(--spacing--xs);
                    overflow-y: scroll;
                    text-align: left;
                    -webkit-overflow-scrolling: touch;
                    grid-area: 1 / 1 / 2 / 2;

                    @media (--media--md) {
                        max-height: 100%;
                    }

                    p {
                        hyphens: auto;
                        text-align: center;
                    }

                    &.gdpr-cookieconsent-settings__content__info__description--settings {
                        display: none;
                    }
                }
            }

            .gdpr-cookieconsent-settings__content__settings {
                order: 2;
                margin-top: 0;
                margin-bottom: var(--spacing--sm);
                /* grid-area: 2 / 1 / 3 / 2; */

                .gdpr-cookieconsent-switch--group {
                    font-size: var(--headline--xs);

                    .gdpr-cookieconsent-switch--cookie {
                        font-size: var(--text--base);
                    }
                }
            }

            .gdpr-cc-button {
                hyphens: auto;
                filter: brightness(1);

                @media (hover: hover) {
                    &:hover {
                        filter: brightness(1);
                    }
                }
            }

            .gdpr-cookieconsent-settings__content__cta {
                order: 3;
                height: auto;
                position: relative;
                padding-top: var(--spacing--sm);
                margin: 0 !important;
                grid-area: 2 / 1 / 3 / 2;

                #gdpr-cc-btn-accept {
                    font-weight: 300;
                    color: white;
                    padding: 1rem 2rem;
                    border: none;
                    border-radius: 30px;
                    transition: all 0.15s var(--easing--in-out);

                    @media (hover: hover) {
                        &:hover {
                            background: #233544;
                        }
                    }
                }

                #gdpr-cc-btn-accept-necessary {
                    font-weight: 300;
                    hyphens: auto;
                    white-space: normal;
                    color: var(--color--font-base);
                    background-color: #e5e5e5;
                    padding: 1rem 2rem;
                    border: none;
                    border-radius: 30px;
                    transition: all 0.35s var(--easing--in-out);

                    @media (hover: hover) {
                        &:hover {
                            background-color: #cccccc;
                        }
                    }
                }

                #gdpr-cc-btn-individual-settings-enable {
                    opacity: 1;
                    font-weight: 300;
                    text-decoration: underline;
                    color: var(--color--primary);
                    padding: 0;
                    margin-top: var(--spacing--md);
                    border: none;
                    transition: all 0.15s var(--easing--in-out);

                    @media (hover: hover) {
                        &:hover {
                            opacity: 0.5;
                        }
                    }
                }

                #gdpr-cc-btn-save {
                    font-weight: 300;
                    color: white;
                    padding: 1rem 2rem;
                    border: none;
                    border-radius: 30px;
                    transition: all 0.15s var(--easing--in-out);

                    @media (hover: hover) {
                        &:hover {
                            background: #233544;
                        }
                    }
                }

                #gdpr-cc-btn-individual-settings-disable {
                    font-weight: 300;
                    hyphens: auto;
                    white-space: normal;
                    color: var(--color--font-base);
                    background-color: #e5e5e5;
                    padding: 1rem 2rem;
                    border: none;
                    border-radius: 30px;
                    transition: all 0.35s var(--easing--in-out);

                    @media (hover: hover) {
                        &:hover {
                            background-color: #cccccc;
                        }
                    }
                }
            }

            .gdpr-cookieconsent-settings__content__links {
                order: 4;
                bottom: 0;
                text-align: center;
                grid-area: 3 / 1 / 4 / 2;

                span {
                    font-weight: 300;
                    color: var(--color--primary);
                }

                a {
                    font-weight: 300;
                    color: var(--color--primary);
                    transition: color 0.35s var(--easing--in-out);

                    &:hover {
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}

.gdpr-cookieconsent-switch {
    --cookieconsent-switch-thumb-size: 1.25rem;
    /* --cookieconsent-switch-thumb-color: rgb(255 255 255); */
    /* --cookieconsent-switch-thumb-highlight-color: rgba(0 0 0 / 0.25); */
    --cookieconsent-switch-length: calc(
        var(--cookieconsent-switch-thumb-size) * 2
    );
    --cookieconsent-switch-padding: 4px;
    --cookieconsent-switch-active-color: var(--cookieconsent-theme-color);
    --cookieconsent-switch-thumb-size-small: 1rem;
    --cookieconsent-switch-outline: var(--cookieconsent-focus-outline);
    --cookieconsent-switch-outline-offset: var(
        --cookieconsent-focus-outline-offset
    );
}
