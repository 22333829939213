#snackbar {
    min-width: 500px;
    min-height: 50px;
    position: fixed;
    top: 30px;
    left: 50%;
    z-index: 10000000;
    font-size: var(--text--base);
    color: var(--color--light);
    background: var(--color--primary);
    visibility: hidden;
    padding: 16px;
    border-radius: 25px;
    transform: translateX(-50%);
    text-align: center;
}

#snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
    from {
        opacity: 0;
        top: 0;
    }
    to {
        opacity: 1;
        top: 30px;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
        top: 0;
    }
    to {
        opacity: 1;
        top: 30px;
    }
}

@-webkit-keyframes fadeout {
    from {
        opacity: 1;
        top: 30px;
    }
    to {
        opacity: 0;
        top: 0;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
        top: 30px;
    }
    to {
        opacity: 0;
        top: 0;
    }
}

form {
    display: block;
    width: 110%;
    padding-left: 2%;
    margin-top: var(--spacing--xl);
    margin-bottom: var(--spacing--xl);
    margin-left: -5%;
    grid-column: small;

    &:before {
        content: "Wobei können wir Sie unterstützen?";
        display: block;
        width: 100%;
        font-size: var(--headline--md);
        font-weight: 700;
        color: var(--color--primary);
        margin-bottom: var(--spacing--md);
        text-align: center;
    }

    /* &:after {
        content: "";
        display: block;
        width: 93%;
        height: 1px;
        background: var(--color--line);
        margin: 3%;
        margin-top: var(--spacing--md);
        margin-bottom: 0;
    } */

    fieldset {
        display: block;
        border: none;

        .clearfix {
            display: block;
            width: 99%;
            height: auto;
            padding: 3%;
            float: left;

            .input {
                input {
                    width: 100%;
                    font-weight: 300;
                    color: var(--color--font-base);
                    background: var(--color--light);
                    padding: 0.5rem 1.25rem;
                    padding-left: 0;
                    overflow: visible;
                    border: none;
                    border-bottom: 1px solid var(--color--line);
                    border-radius: 0;

                    &::placeholder {
                        opacity: 0.4;
                    }

                    &:focus-visible {
                        outline: none;
                        border-bottom: 1px solid var(--color--font-base);
                    }
                }

                textarea {
                    width: 100%;
                    height: 200px;
                    font-weight: 300;
                    color: var(--color--font-base);
                    background: var(--color--light);
                    padding: var(--spacing--xs);
                    margin-top: var(--spacing--xs);
                    resize: vertical;
                    border: none;
                    border: 1px solid var(--color--line);
                    border-radius: var(--radius--sm);

                    &::placeholder {
                        font-weight: 300;
                        color: var(--color--line);
                    }

                    &:focus-visible {
                        outline: none;
                        border: 1px solid var(--color--font-base);
                    }
                }
            }

            &.checkbox {
                display: flex;
                flex-direction: row;
                width: 93%;
                background: var(--color--gray);
                padding: var(--spacing--sm);
                margin-left: 3%;
                border-radius: var(--radius--md);
                gap: var(--spacing--sm);

                input {
                    &:focus-visible {
                        outline: auto;
                    }
                }

                &.error {
                    border: 1px solid rgb(156, 10, 7);

                    &.filled {
                        border: 1px solid rgb(8 186 34);
                    }

                    .help-inline {
                        display: none;
                    }
                }

                &.checkbox-input {
                    order: 1;
                }

                label {
                    order: 2;
                }
            }

            &.error {
                input {
                    margin-bottom: 5px;
                    border-bottom: 1px solid rgb(156, 10, 7);

                    &:focus-visible {
                        border-bottom: 1px solid rgb(156, 10, 7);
                    }
                }

                .help-inline {
                    font-size: var(--text--xs);
                    color: rgb(156, 10, 7);
                    margin-top: 5px;
                }

                .currentOption,
                input,
                textarea {
                    color: var(--color--font-base);
                }

                label {
                    color: var(--color--font-base);
                }
            }

            &.filled {
                input {
                    border-bottom: 1px solid rgb(8 186 34);

                    &:focus-visible {
                        border-bottom: 1px solid rgb(8 186 34);
                    }
                }

                .help-inline {
                    color: rgb(8 186 34);
                }

                .currentOption,
                input,
                textarea {
                    color: var(--color--font-base);
                }
            }

            &.w50 {
                display: inline;
                width: 100%;
                height: auto;

                @media (--media--md) {
                    width: 49.5%;
                }
            }

            &.w33 {
                display: inline;
                width: 100%;
                height: auto;

                @media (--media--md) {
                    width: 33%;
                }
            }

            ul.inputs-list {
                display: inline-block;
                width: 100%;
                float: left;

                li {
                    list-style: none;
                    display: inline;
                    width: 100%;

                    &:last-child {
                        margin-right: 0;
                    }

                    label {
                        display: block;
                        position: relative;
                        margin-right: 5px;
                        float: left;

                        &.active {
                            span {
                                color: var(--color--font-base);
                                transition: all 0.15s var(--easing--in-out);

                                &:before {
                                    background: var(--color--primary);
                                    border: 1px solid var(--color--primary);
                                    border: 1px solid var(--color--font-base);
                                    transition: all 0.15s var(--easing--in-out);
                                }

                                &:after {
                                    opacity: 100;
                                    filter: invert(48%) sepia(0%)
                                        saturate(1004%) hue-rotate(135deg)
                                        brightness(90%) contrast(90%);
                                    transition: all 0.15s var(--easing--in-out);
                                }
                            }
                        }

                        input {
                            display: none;
                        }

                        span {
                            display: flex;
                            position: relative;
                            font-size: 1rem;
                            font-weight: 400;
                            color: var(--color--font-base);
                            background: #ececec;
                            padding: 0.5rem 0.5rem 0.5rem 2rem;
                            float: left;
                            border: 2px solid #ececec;
                            border-radius: 100px;
                            cursor: pointer;
                            transition: all 0.15s var(--easing--in-out);
                            text-align: center;

                            @media (hover: hover) {
                                &:hover {
                                    &:before {
                                        background: var(--color--primary);
                                    }
                                }
                            }

                            &:before {
                                content: "";
                                display: block;
                                min-width: 25px;
                                min-height: 25px;
                                position: absolute;
                                top: 50%;
                                left: 1rem;
                                background: none;
                                margin-right: 0.5rem;
                                border: 1px solid var(--color--font-base);
                                border-radius: 100px;
                                transform: translate(-50%, -50%);
                                transition: all 0.15s var(--easing--in-out);
                            }

                            &:after {
                                content: url("/_Resources/Static/Packages/LC.Site/Images/checkmark.svg");
                                display: block;
                                opacity: 0;
                                min-width: 20px;
                                min-height: 20px;
                                position: absolute;
                                top: calc(50% + 0.15rem);
                                left: 1rem;
                                transform: translate(-50%, -50%);
                                transition: all 0.15s var(--easing--in-out);
                            }
                        }
                    }
                }
            }
        }

        p {
            display: block;
            width: 99%;
            height: auto;
            padding: 3%;
            float: left;
        }
    }

    .form-navigation {
        display: block;
        width: 100%;
        margin-top: var(--spacing--md);

        ul {
            display: flex;
            justify-content: center;
            align-items: center;

            li {
                list-style: none;
                display: inline;

                button {
                    font-weight: 300;
                    color: var(--color--font-base);
                    padding: 1rem 2rem;
                    border: 1px solid var(--color--font-base);
                    border-radius: 30px;
                    transition: all 0.15s var(--easing--in-out);

                    @media (hover: hover) {
                        &:hover {
                            color: var(--color--light);
                            background-color: var(--color--primary);
                            border: 1px solid var(--color--primary);
                        }
                    }
                    &:focus-visible {
                        outline: auto;
                    }
                }
            }
        }
    }
}
