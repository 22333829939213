header.header {
    display: grid;
    width: 100%;
    height: 105px;
    position: fixed;
    top: 0;
    z-index: 9999;
    background: var(--color--light);
    padding: var(--spacing--xs) 0;
    border-bottom: 4px solid var(--color--gray);
    touch-action: manipulation;

    .logo {
        display: inline-block;
        z-index: 10000;

        a {
            opacity: 1;
            transition: all 0.15s var(--easing--in-out);

            @media (hover: hover) {
                &:hover {
                    opacity: 0.5;
                }
            }
        }

        svg {
            display: block;
            height: 40px;

            @media (--media--md) {
                height: 60px;
            }

            .st1 {
                fill: var(--color--logo1);
            }

            .st0 {
                fill: var(--color--logo2);
            }
        }
    }

    &.active {
        .background {
            display: block;
        }

        .logo {
            display: inline-block;
        }
    }
}
