.tab-item {
    display: none;
    opacity: 0;
    transition: all 0.65s var(--easing--in-out);

    &.active {
        display: block;
        opacity: 1;
    }
}
