:root {
    --alle: var(--color--font-base);
    --herren1: #7dc4c2;
    --herren60: #6988a1;
    --damen1: #f49c56;
    --damen30: #ed7095;
    --damen40: #9b407a;
}

.filterbuttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 100%;
    z-index: 1000;
    margin-bottom: var(--spacing--sm);

    .button {
        display: inline-block;
        justify-content: center;
        align-items: center;
        width: auto;
        color: var(--color--light);
        background-color: white;
        padding: 0.25rem 0.75rem;
        margin-right: 0.25rem;
        margin-bottom: 0.25rem;
        border: 2px solid;
        border-radius: 20px;
        cursor: pointer;

        @media (--media--md) {
        }

        @media (hover: hover) {
            &:hover {
                &.alle {
                    opacity: 0.7;
                    background: var(--alle);
                }

                &.herren1 {
                    opacity: 0.7;
                    background: var(--herren1);
                }

                &.herren60 {
                    opacity: 0.7;
                    background: var(--herren60);
                }

                &.damen1 {
                    opacity: 0.7;
                    background: var(--damen1);
                }

                &.damen30 {
                    opacity: 0.7;
                    background: var(--damen30);
                }

                &.damen40 {
                    opacity: 0.7;
                    background: var(--damen40);
                }
            }
        }

        &.alle {
            &.active {
                color: var(--alle);
                background-color: white;
                border: 2px solid var(--alle);
                pointer-events: none;
            }
        }

        &.herren1 {
            &.active {
                color: var(--herren1);
                background-color: white;
                border: 2px solid var(--herren1);
                pointer-events: none;
            }
        }

        &.herren60 {
            &.active {
                color: var(--herren60);
                background-color: white;
                border: 2px solid var(--herren60);
                pointer-events: none;
            }
        }

        &.damen1 {
            &.active {
                color: var(--damen1);
                background-color: white;
                border: 2px solid var(--damen1);
                pointer-events: none;
            }
        }

        &.damen30 {
            &.active {
                color: var(--damen30);
                background-color: white;
                border: 2px solid var(--damen30);
                pointer-events: none;
            }
        }

        &.damen40 {
            &.active {
                color: var(--damen40);
                background-color: white;
                border: 2px solid var(--damen40);
                pointer-events: none;
            }
        }

        &.alle {
            background-color: var(--alle);
        }

        &.herren1 {
            background-color: var(--herren1);
        }

        &.herren60 {
            background-color: var(--herren60);
        }

        &.damen1 {
            background-color: var(--damen1);
        }

        &.damen30 {
            background-color: var(--damen30);
        }

        &.damen40 {
            background-color: var(--damen40);
        }
    }
}

ul.match-list {
    max-width: 100%;

    li.heading {
        display: none;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-row-gap: 10px;
        align-items: center;
        padding: 0.5rem 0;

        @media (--media--md) {
            grid-template-columns: 1fr 0.75fr repeat(2, 1.5fr) repeat(1, 0.75fr);

            grid-template-rows: 1fr;
            border-bottom: 1px solid var(--color--font-base);

            span {
                display: none;
            }
        }

        @media (--media--md) {
            display: grid;
        }

        .text {
            opacity: 0.7;
        }
    }

    .table-item {
        margin-bottom: var(--spacing--xs);
        overflow: hidden;
        border-radius: 25px;
        box-shadow: var(--shadow--sm);

        @media (--media--md) {
            padding: 0;
            margin-bottom: 0;
            border-radius: 0;
            box-shadow: none;
        }

        div.icon {
            width: 0.75rem;
            height: 0.75rem;
            position: absolute;
            top: 50%;
            right: 1.25rem;
            transform: translateY(-50%);

            span {
                display: block;
                width: 100%;
                height: 3px;
                position: absolute;
                top: 50%;
                background: var(--color--font-base);
                border-radius: 10px;
                transition: all 0.35s var(--easing--in-out);

                &:first-child {
                    transform: translateY(-50%) rotate(-90deg);
                }

                &:last-child {
                    transform: translateY(-50%);
                }
            }
        }

        &.active {
            .match {
                display: grid;
            }

            div.icon {
                span:first-child {
                    transform: translateY(-50%) rotate(0deg);
                }
            }
        }

        .mobile-heading {
            display: flex;
            flex-direction: column;
            justify-content: left;
            align-items: left;
            position: relative;
            background: var(--color--dark);
            padding: 1rem;
            padding-left: 3rem;

            @media (--media--md) {
                display: none;
            }

            &:before {
                content: ;
            }

            .date {
                width: fit-content;
                font-weight: 900;
            }

            .team1 {
                width: fit-content;
                text-align: left;
            }

            &.herren1 {
                &:before {
                    content: "";
                    display: inline-block;
                    min-width: 25px;
                    max-height: 25px;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    background-color: var(--herren1);
                    margin-right: 10px;
                    margin-bottom: 6px;
                    border-radius: 30px;
                    transform: translate(50%, -50%);
                    aspect-ratio: 1 / 1;
                }
            }

            &.herren60 {
                &::before {
                    content: "";
                    display: inline-block;
                    min-width: 25px;
                    max-height: 25px;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    background-color: var(--herren60);
                    margin-right: 10px;
                    margin-bottom: 6px;
                    border-radius: 30px;
                    transform: translate(50%, -50%);
                    aspect-ratio: 1 / 1;
                }
            }

            &.damen1 {
                &::before {
                    content: "";
                    display: inline-block;
                    min-width: 25px;
                    max-height: 25px;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    background-color: var(--damen1);
                    margin-right: 10px;
                    margin-bottom: 6px;
                    border-radius: 30px;
                    transform: translate(50%, -50%);
                    aspect-ratio: 1 / 1;
                }
            }

            &.damen30 {
                &::before {
                    content: "";
                    display: inline-block;
                    min-width: 25px;
                    max-height: 25px;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    background-color: var(--damen30);
                    margin-right: 10px;
                    margin-bottom: 6px;
                    border-radius: 30px;
                    transform: translate(50%, -50%);
                    aspect-ratio: 1 / 1;
                }
            }

            &.damen40 {
                &::before {
                    content: "";
                    display: inline-block;
                    min-width: 25px;
                    max-height: 25px;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    background-color: var(--damen40);
                    margin-right: 10px;
                    margin-bottom: 6px;
                    border-radius: 30px;
                    transform: translate(50%, -50%);
                    aspect-ratio: 1 / 1;
                }
            }
        }

        li.match {
            display: none;
            grid-template-columns: 3fr 7fr;
            grid-template-rows: repeat(4, 1fr);
            grid-row-gap: 10px;
            align-items: center;
            background: white;
            padding: 1rem;

            @media (--media--md) {
                display: grid;
                grid-template-columns: 1fr 0.75fr repeat(2, 1.5fr) repeat(
                        1,
                        0.75fr
                    );

                grid-template-rows: 1fr;
                background: none;
                padding: 0.5rem 0;
                border-bottom: 1px solid var(--color--font-base);
                span {
                    display: none;
                }
            }

            span {
                opacity: 0.7;
                font-weight: 400;
                grid-column: 1 / 1 / 1 / 2;
            }

            a {
                text-decoration: underline;
                transition: opacity 0.15s var(--easing--in-out);

                @media (hover: hover) {
                    &:hover {
                        opacity: 0.7;
                    }
                }

                @media not (--media--md) {
                    display: inline-block;
                    font-size: var(--text--base);
                    line-height: var(--text--base-height);
                    letter-spacing: 0.5px;
                    text-decoration: none;
                    color: var(--color--font-base);
                    background-color: var(--color--primary);
                    padding: 0.5rem 2rem;
                    border-radius: 30px;
                }
            }

            .text {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-weight: 900;

                &.herren1 {
                    &::before {
                        content: "";
                        display: none;
                        min-width: 16px;
                        max-height: 16px;
                        background-color: var(--herren1);
                        margin-right: 10px;
                        margin-bottom: 2px;
                        border-radius: 8px;
                        aspect-ratio: 1 / 1;

                        @media (--media--md) {
                            display: block;
                        }
                    }
                }

                &.herren60 {
                    &::before {
                        content: "";
                        display: none;
                        min-width: 16px;
                        max-height: 16px;
                        background-color: var(--herren60);
                        margin-right: 10px;
                        margin-bottom: 2px;
                        border-radius: 8px;
                        aspect-ratio: 1 / 1;

                        @media (--media--md) {
                            display: block;
                        }
                    }
                }

                &.damen1 {
                    &::before {
                        content: "";
                        display: none;
                        min-width: 16px;
                        max-height: 16px;
                        background-color: var(--damen1);
                        margin-right: 10px;
                        margin-bottom: 2px;
                        border-radius: 8px;
                        aspect-ratio: 1 / 1;

                        @media (--media--md) {
                            display: block;
                        }
                    }
                }

                &.damen30 {
                    &::before {
                        content: "";
                        display: none;
                        min-width: 16px;
                        max-height: 16px;
                        background-color: var(--damen30);
                        margin-right: 10px;
                        margin-bottom: 2px;
                        border-radius: 8px;
                        aspect-ratio: 1 / 1;

                        @media (--media--md) {
                            display: block;
                        }
                    }
                }

                &.damen40 {
                    &::before {
                        content: "";
                        display: none;
                        min-width: 16px;
                        max-height: 16px;
                        background-color: var(--damen40);
                        margin-right: 10px;
                        margin-bottom: 2px;
                        border-radius: 8px;
                        aspect-ratio: 1 / 1;

                        @media (--media--md) {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
