/* Padding disable */
.padding-top--disable {
    padding-top: 0 !important;
}
.padding-bottom--disable {
    padding-bottom: 0 !important;
}

/* Padding xs */
.padding-top--xs {
    padding-top: var(--spacing--xs) !important;
}
.padding-bottom--xs {
    padding-bottom: var(--spacing--xs) !important;
}

/* Padding sm */
.padding-top--sm {
    padding-top: var(--spacing--sm) !important;
}
.padding-bottom--sm {
    padding-bottom: var(--spacing--sm) !important;
}

/* Padding md */
.padding-top--md {
    padding-top: var(--spacing--md) !important;
}
.padding-bottom--md {
    padding-bottom: var(--spacing--md) !important;
}

/* Padding lg */
.padding-top--lg {
    padding-top: var(--spacing--lg) !important;
}
.padding-bottom--lg {
    padding-bottom: var(--spacing--lg) !important;
}

/* Padding xl */
.padding-top--xl {
    padding-top: var(--spacing--xl) !important;
}
.padding-bottom--xl {
    padding-bottom: var(--spacing--xl) !important;
}

/* Padding xxl */
.padding-top--xxl {
    padding-top: var(--spacing--xxl) !important;
}
.padding-bottom--xxl {
    padding-bottom: var(--spacing--xxl) !important;
}
