:root {
    /* colors */
    --color--primary: #314b60;
    --color--light: #fff;
    --color--dark: #1e1e1e;
    --color--gray: #f8f8f8;
    --color--font-base: #1e1e1e;
    --color--line: #d8d8d8;
    --color--logo1: #ffd932;
    --color--logo2: #7a7a7a;

    /* typography */
    --font--base-family: Poppins;
    --font--headline-family: Poppins;
    --font--base-size: 16px;

    /* spacing */
    --spacing--xs: 0.75rem;
    --spacing--sm: 1.5rem;
    --spacing--md: 3rem;
    --spacing--lg: 6rem;
    --spacing--xl: 8rem;
    --spacing--xxl: 10rem;

    /* radius */
    --radius--sm: 0.5rem;
    --radius--md: 1rem;
    --radius--lg: 2rem;

    /* shadow */
    --shadow--sm: 0 0px 10px rgba(0, 0, 0, 0.1);
    --shadow--md: 0 0px 16px rgba(0, 0, 0, 0.175);
    --shadow--lg: 0 0px 30px rgba(0, 0, 0, 0.2);

    /* easing */
    --easing--in-out: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    --easing--in: cubic-bezier(0.6, 0.04, 0.98, 0.335);
    --easing--out: cubic-bezier(0.075, 0.82, 0.165, 1);

    /* @media (prefers-color-scheme: dark) {
        --color--primary: #7faed0;
        --color--light: #1f1f1f;
        --color--dark: #2b2b2b;
        --color--gray: #232323;
        --color--font-base: #f8f8f8;
        --color--line: #8e8e8e;
        --color--logo1: #ffd932;
        --color--logo2: #d9d9d9;
    } */

    /* text-size*/

    /* small */
    @media (--media--sm) {
        --text--xs: 0.9rem;
    }
    @media (--media--md) {
        --text--xs: 0.9rem;
    }
    @media (--media--sm) {
        --text--xs-height: 1;
    }
    @media (--media--md) {
        --text--xs-height: 1;
    }

    /* base */
    @media (--media--sm) {
        --text--base: 1rem;
    }
    @media (--media--md) {
        --text--base: 1rem;
    }
    @media (--media--sm) {
        --text--base-height: 1.5;
    }
    @media (--media--md) {
        --text--base-height: 1.5;
    }

    /* large */
    @media (--media--sm) {
        --text--xl: 1.25rem;
    }
    @media (--media--md) {
        --text--xl: 1.5rem;
    }
    @media (--media--sm) {
        --text--xl-height: 1.3;
    }
    @media (--media--md) {
        --text--xl-height: 1.3;
    }

    /* headline-size*/

    /* xs */

    @media (--media--sm) {
        --headline--xs: 1.25rem;
    }
    @media (--media--md) {
        --headline--xs: 1.25rem;
    }
    @media (--media--sm) {
        --headline--xs-height: 1;
    }
    @media (--media--md) {
        --headline--xs-height: 1;
    }

    /* sm */

    @media (--media--sm) {
        --headline--sm: 1.75rem;
    }
    @media (--media--md) {
        --headline--sm: 1.75rem;
    }
    @media (--media--sm) {
        --headline--sm-height: 1;
    }
    @media (--media--md) {
        --headline--sm-height: 1;
    }

    /* md */

    @media (--media--sm) {
        --headline--md: 2rem;
    }
    @media (--media--md) {
        --headline--md: 2rem;
    }
    @media (--media--sm) {
        --headline--md-height: 1;
    }
    @media (--media--md) {
        --headline--md-height: 1;
    }

    /* lg */

    @media (--media--sm) {
        --headline--lg: 2rem;
    }
    @media (--media--md) {
        --headline--lg: 3rem;
    }
    @media (--media--sm) {
        --headline--lg-height: 1;
    }
    @media (--media--md) {
        --headline--lg-height: 1;
    }

    /* xl */
    @media (--media--sm) {
        --headline--xl: 2rem;
    }
    @media (--media--md) {
        --headline--xl: 4rem;
    }

    @media (--media--sm) {
        --headline--xl-height: 1;
    }
    @media (--media--md) {
        --headline--xl-height: 1;
    }

    /*globalclasses*/
    .hidden {
        display: none;
    }
}

/* breakpoints with custom media, because css variables is not supportet in media queries */
@custom-media --media--sm (min-width: 300px);
@custom-media --media--md (min-width: 1250px);
@custom-media --media--lg (min-width: 1500px);
@custom-media --media--xl (min-width: 2000px);
