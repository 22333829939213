.tile-person {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    max-width: 100%;
    height: auto;
    position: relative;
    margin-bottom: var(--spacing--xs);
    border: 1px solid var(--color--line);
    border-radius: var(--radius--lg);
    transition: all 0.35s var(--easing--in-out);

    @media (hover: hover) {
        &:hover {
            border: 1px solid var(--color--primary);
            .media-content {
                img {
                    filter: none;
                }
            }
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    /* .media-content {
        width: 100%;
        height: 100%;
        position: relative;
        grid-area: 1 / 2 / 2 / 3;

        @media (--media--md) {
            left: 50%;
            margin-top: 0;
            margin-bottom: 0;
            margin-left: 0;
            transform: translateX(-50%);
            grid-area: 1 / 2 / 2 / 3;
        }

        .frame {
            display: block;
            width: 100%;
            height: 140%;
            position: absolute;
            bottom: 0;

            @media (--media--md) {
                height: 120%;
            }

            img {
                width: 100%;
                height: 100%;
                filter: grayscale(100%);
                object-fit: cover;
                object-position: center top;
                transition: all 0.35s var(--easing--in-out);
            }
        }
    } */

    .text-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        height: 100%;
        hyphens: none;
        background: none;
        padding: var(--spacing--sm);
        margin-left: 0;

        @media (--media--md) {
            padding: var(--spacing--md);
        }

        .headline {
            h5 {
                font-size: var(--headline--sm);
                line-height: var(--headline--lg-height);
                font-weight: 400;
                color: var(--color--primary);
            }
        }

        p {
            hyphens: none;
        }

        .paragraph {
            font-weight: 300;

            &.text--xs {
                td {
                    font-size: var(--text--xs);
                }
            }

            &.text--base {
                td {
                    font-size: var(--text--base);
                }
            }

            &.text--xl {
                td {
                    font-size: var(--text--xl);
                }
            }

            table {
                tr {
                    border: none;

                    td {
                        font-weight: 300;
                        hyphens: none;
                        padding-right: var(--spacing--sm);

                        strong {
                            font-weight: 500;
                        }

                        a {
                            font-weight: 300;
                        }
                    }
                }
            }
        }

        .pop-up {
            display: none;
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 10000000;
            background: rgb(0 0 0 / 0.5);
            backdrop-filter: blur(10px);

            @media (--media--md) {
                display: none;

                &.active {
                    display: block;
                }
            }

            .qr-code-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 50%;
                left: 50%;
                background: var(--color--gray);
                border-radius: 25px;
                transform: translate(-50%, -50%);

                button.close {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50px;
                    height: 50px;
                    background: white;
                    margin-bottom: var(--spacing--md);
                    border: 1px solid var(--color--font-base);
                    border-radius: 100px;
                    transition: all 0.15s var(--easing--in-out);

                    @media (hover: hover) {
                        &:hover {
                            background: var(--color--primary);
                            border: 1px solid var(--color--primary);

                            svg {
                                line.st0 {
                                    stroke: var(--color--light);
                                }
                            }
                        }
                    }

                    svg {
                        width: 25px;
                        height: 25px;

                        line.st0 {
                            transition: all 0.15s var(--easing--in-out);
                            stroke: var(--color--primary);
                            stroke-width: 5px;
                        }
                    }
                }

                #canvas {
                    width: 350px;
                    height: 350px;

                    #qrcode {
                        background: white;
                        margin: var(--spacing--md);
                        border: 1px solid var(--color--line);
                        border-radius: 10px;

                        svg {
                            width: 100%;
                            height: 100%;
                            padding: var(--spacing--sm);

                            path {
                                fill: var(--color--primary);
                            }
                        }
                    }
                }
            }
        }
    }
}
