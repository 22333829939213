.popup-blur {
    display: none;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 1000000;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(20px);

    &.active {
        display: flex;
        flex-direction: column;
    }
}

.popup-header {
    .count {
        position: fixed;
        top: 10px;
        color: var(--color--primary);
    }
}

.img-popup {
    display: none;
    position: absolute;

    .popup-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 50%;

        @media (--media--md) {
            padding-bottom: 0;
        }

        .prev-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            position: fixed;
            bottom: 20%;
            left: 2rem;
            background: var(--color--primary);
            border-radius: 100px;
            transition: all 0.15s var(--easing--in-out);
            touch-action: manipulation;

            @media (--media--md) {
                bottom: 50%;
            }

            @media (hover: hover) {
                &:hover {
                    transform: scale(1.1);
                    svg {
                        path {
                            opacity: 0.5;
                            transition: all 0.15s var(--easing--in-out);
                        }
                    }
                }
            }

            svg {
                width: auto;
                height: 60%;
                margin-right: 5px;
                transform: rotate(180deg);
                path {
                    stroke: var(--color--font-base);
                }
            }
        }

        img {
            width: auto;
            max-width: 100vw;
            height: auto;
            max-height: 80vh;
            border-radius: 0;

            @media (--media--md) {
                max-width: 70vw;
                border-radius: 10px;
            }
        }

        .next-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            position: fixed;
            bottom: 20%;
            right: 2rem;
            background: var(--color--primary);
            border-radius: 100px;
            transition: all 0.15s var(--easing--in-out);
            touch-action: manipulation;

            @media (--media--md) {
                bottom: 50%;
            }
            @media (hover: hover) {
                &:hover {
                    transform: scale(1.1);
                    svg {
                        path {
                            opacity: 0.5;
                            transition: all 0.15s var(--easing--in-out);
                        }
                    }
                }
            }

            svg {
                width: auto;
                height: 60%;
                margin-left: 5px;
                path {
                    stroke: var(--color--font-base);
                }
            }
        }
    }

    &.active {
        display: flex;
        z-index: 100000000;
    }
}

.gallery-image {
    width: 100%;
    height: auto;
    overflow: hidden;
    cursor: zoom-in;
    transition: all 0.25s var(--easing--in-out);
    aspect-ratio: 1 / 1;

    @media (hover: hover) {
        &:hover {
            transform: scale(1.025);
            transition: all 0.25s var(--easing--in-out);
        }
    }

    @media screen and (max-width: 880px) {
        .container .container__img-holder:nth-child(3n + 1) {
            margin-left: 16px;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 20px;
    background-color: var(--color--third);
    margin-top: 1rem;
    border-radius: 100px;
    cursor: pointer;
    transform: scale(1);
    transition: all 0.35s var(--easing--in-out);

    @media (hover: hover) {
        &:hover {
            box-shadow: var(--shadow--sm);
            transform: scale(1.1);
            transition: all 0.15s var(--easing--in-out);
        }
    }

    div.bar {
        width: 16px;
        height: 3px;
        background-color: white;
        border-radius: 10px;
        pointer-events: none;

        &:first-child {
            transform: translateX(8px) rotate(45deg);
        }

        &:last-child {
            transform: translateX(-8px) rotate(-45deg);
        }
    }
}
