.hero-banner {
    display: flex;
    align-items: center;
    width: 100vw;
    z-index: 1;
    padding-top: var(--spacing--md);
    margin-top: 94px;
    text-align: right;

    @media (--media--md) {
        min-width: 100vw;
        min-height: 100vh;
        padding-top: 0;
        margin-top: 0;
    }

    .hero-media {
        width: 100%;
        height: 40vh;
        position: relative;
        grid-area: space;

        @media (--media--md) {
            width: 50%;
            height: 100%;
            position: absolute;
            left: 50%;
            z-index: 0;
            overflow: hidden;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .hero-content {
        width: 100%;
        position: relative;
        z-index: 1;
        color: var(--color--font-base);
        padding: var(--spacing--md) 0;
        margin-bottom: var(--spacing--md);
        grid-column: large;
        text-align: left;
        grid-column: large-start / large-end;

        @media (--media--md) {
            padding: 0;
            margin-bottom: 0;
            grid-column: large-start / center-start;
        }

        p {
            hyphens: none;
        }

        .lead {
            font-weight: 300;
            padding-top: 0;

            @media (--media--md) {
                padding-top: 95px;
            }
        }
        .headline {
            h1 {
                font-weight: 700;
                hyphens: none;
                color: var(--color--primary);

                @media (--media--md) {
                    hyphens: auto;
                }
            }
        }
        .text {
            font-weight: 300;
        }
    }
}
