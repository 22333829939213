div.accordion--item {
    display: block;
    width: 100%;
    height: fit-content;
    background: var(--color--light);
    margin-bottom: var(--spacing--sm);
    overflow: hidden;
    border: 1px solid var(--color--line);
    border-radius: var(--radius--md);
    transition: all 0.15s var(--easing--in-out);

    button {
        width: 100%;
        position: relative;
        font-size: var(--text--base);
        line-height: var(--text--base-height);
        font-weight: 300;
        color: var(--color--primary);
        padding: var(--spacing--sm) var(--spacing--md) var(--spacing--sm)
            var(--spacing--sm);
        text-align: left;

        @media (--media--md) {
            padding: var(--spacing--sm) var(--spacing--md);
        }

        div.icon {
            width: 1rem;
            height: 1rem;
            position: absolute;
            top: 50%;
            right: var(--spacing--sm);
            transform: translateY(-50%);

            @media (--media--md) {
                right: var(--spacing--md);
            }

            span {
                display: block;
                width: 100%;
                height: 2px;
                position: absolute;
                top: 50%;
                background: var(--color--font-base);
                border-radius: 10px;
                transition: all 0.35s var(--easing--in-out);

                &:first-child {
                    transform: translateY(-50%) rotate(-90deg);
                }

                &:last-child {
                    transform: translateY(-50%);
                }
            }
        }
    }

    div.container {
        width: 100%;
        height: 0;
        background: var(--color--gray);
        padding: 0 var(--spacing--sm);
        overflow: hidden;
        border-radius: var(--radius--md) var(--radius--md) 0 0;

        @media (--media--md) {
            padding: 0 var(--spacing--md);
        }

        .keypoints {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media (--media--md) {
                flex-direction: row;
            }

            p {
                width: fit-content;
                padding-bottom: var(--spacing--xs);
                margin-bottom: var(--spacing--xs);
                border-right: none;
                border-bottom: 1px solid var(--color--line);

                @media (--media--md) {
                    width: 100%;
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-right: 1px solid var(--color--line);
                    border-bottom: none;
                }

                &:last-child {
                    margin-bottom: 0;
                    border: none;
                }
            }
        }

        .contact {
            display: flex;
            flex-direction: column;
            align-content: stretch;
            width: 100%;
            margin: var(--spacing--sm) 0;
            margin-bottom: var(--spacing--sm);
            gap: var(--spacing--sm);

            @media (--media--md) {
                flex-direction: row;
                margin-bottom: var(--spacing--md);
            }

            a {
                opacity: 100;
                text-decoration: none !important;
            }

            .phone {
                width: 100%;
                padding: var(--spacing--sm);
                border: 1px solid var(--color--line);
                border-radius: var(--radius--sm);
                transition: all 0.15s var(--easing--in-out);

                @media (--media--md) {
                    width: 50%;
                }

                @media (hover: hover) {
                    &:hover {
                        background-color: var(--color--light);
                        border: 1px solid var(--color--primary);
                    }
                }
            }

            .mail {
                width: 100%;
                padding: var(--spacing--sm);
                border: 1px solid var(--color--line);
                border-radius: var(--radius--sm);
                transition: all 0.25s var(--easing--in-out);

                @media (--media--md) {
                    width: 50%;
                }

                @media (hover: hover) {
                    &:hover {
                        background-color: var(--color--light);
                        border: 1px solid var(--color--primary);
                    }
                }
            }
        }
    }

    &.active {
        border: 1px solid var(--color--primary);

        button {
            color: var(--color--primary);

            div.icon {
                span:first-child {
                    transform: translateY(-50%) rotate(0deg);
                }

                span {
                    background: var(--color--primary);
                }
            }
        }

        div.container {
            height: fit-content;
        }
    }
}
