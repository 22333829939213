html {
    font-size: var(--font--base-size);
    scroll-behavior: smooth;
    scroll-padding-top: var(--spacing--md);
}

body {
    max-width: 100vw;
    font-family: var(--font--base-family);
    font-size: var(--font--base-size);
    font-weight: 300;
    color: var(--color--font-base);
    background: var(--color--light);
    overflow-x: hidden;
    overflow-y: scroll;

    &.active {
        height: 100vh;
        overflow: hidden;
    }
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
}

.neos-backend {
    .neos-contentcollection {
        & > div {
            width: 100%;
        }
    }
}

::selection {
    text-shadow: none;
    color: var(--color--light);
    background: var(--color--primary);
}

::-moz-selection {
    text-shadow: none;
    color: var(--color--light);
    background: var(--color--primary);
}
