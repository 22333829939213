.text-wrapper {
    display: flex;
    justify-content: flex;
    align-items: center;
    margin: var(--spacing--md) 0;
    grid-column: large-start / large-end;

    @media (--media--md) {
        margin: 0;
        grid-column: large-start / center-start;
    }

    .text-content {
        width: 100%;

        .day-time {
            display: flex;
            flex-direction: column;
            margin-top: var(--spacing--sm);

            @media (--media--md) {
                display: flex;
                flex-direction: row;
                margin-top: 0;
            }
        }

        span {
            display: block;
            width: 100%;
            height: 1px;
            background: var(--color--line);
            margin: var(--spacing--sm) 0;
        }

        table {
            tr {
                border: none;

                td {
                    font-weight: 300;
                    padding-right: var(--spacing--sm);

                    strong {
                        font-weight: 500;
                    }

                    a {
                        font-weight: 300;
                    }
                }
            }
        }
    }
}
