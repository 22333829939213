footer.footer {
    background: var(--color--gray);
    padding: var(--spacing--lg) 0;
    padding-bottom: 0;

    .wrapper {
        display: flex;
        flex-direction: row;

        &.top {
            display: flex;
            flex-direction: column;
            padding-bottom: var(--spacing--md);
            border-bottom: 1px solid var(--color--line);

            @media (--media--md) {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
            }

            .logo {
                display: inline-block;
                margin-bottom: var(--spacing--lg);

                @media (--media--md) {
                    grid-area: 1 / 1 / 2 / 2;
                }

                svg {
                    display: block;
                    height: 80px;

                    @media (--media--md) {
                        height: 120px;
                    }

                    .st1 {
                        fill: var(--color--logo1);
                    }

                    .st0 {
                        fill: var(--color--logo2);
                    }
                }
            }

            .text {
                grid-area: 1 / 3 / 2 / 4;

                .paragraph {
                    gap: var(--spacing--sm);
                }

                .headline {
                    font-weight: 500;
                    color: var(--color--primary);
                }

                .time {
                    display: flex;
                    flex-direction: column;
                    height: auto;
                    padding: var(--spacing--sm) 0;
                    border-bottom: 1px solid var(--color--line);

                    @media (--media--md) {
                        flex-direction: row;
                    }

                    p {
                        width: 100%;
                        gap: 0;

                        @media (--media--md) {
                            width: 50%;
                        }
                    }
                }

                .adress {
                }

                .contact {
                    table {
                        white-space: nowrap;
                        tr {
                            border: none;

                            td {
                                font-weight: 300;
                                padding-right: var(--spacing--sm);

                                strong {
                                    font-weight: 500;
                                }

                                a {
                                    font-weight: 300;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.bottom {
            .navigation--footer {
                width: 100%;
                padding: var(--spacing--sm) 0;
                .navigation--list {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;

                    a {
                        opacity: 0.5;
                        font-size: 0.9rem;
                        text-decoration: none;
                        transition: all 0.25s var(--easing--in-out);

                        @media (hover: hover) {
                            &:hover {
                                opacity: 1;
                            }
                        }
                    }

                    &:nth-child(1) {
                    }
                    &:nth-child(2) {
                    }
                }
            }
        }
    }
}
