* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
}

header,
main,
section,
aside,
footer {
    display: block;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

pre {
    font-family: monospace, monospace; /* 1 */
    font-size: 1em; /* 2 */
}

a {
    text-decoration: underline;
    color: var(--color--primary);
    background-color: transparent;
    outline: none;

    @media (hover: hover) {
        &:hover {
            opacity: 0.5;
        }
    }
}

b,
strong {
    font-weight: 400;
    color: var(--color--primary);
}

code,
kbd,
samp {
    font-family: monospace, monospace; /* 1 */
    font-size: 1em; /* 2 */
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

img {
    border-style: none;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: 1.15; /* 1 */
    margin: 0; /* 2 */
}

button,
input {
    /* 1 */
    overflow: visible;
}

button,
select {
    /* 1 */
    text-transform: none;
}

button {
    color: unset;
    background-color: unset;
    border: none;
    cursor: pointer;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}
/* 
fieldset {
    padding: 0.35em 0.75em 0.625em;
} */

legend {
    box-sizing: border-box;
    display: table;
    max-width: 100%;
    white-space: normal;
    color: inherit;
    padding: 0;
}

progress {
    vertical-align: baseline;
}

textarea {
    overflow: auto;
}

[type="checkbox"],
[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px; /* 2 */
    -webkit-appearance: textfield; /* 1 */
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit; /* 2 */
    -webkit-appearance: button; /* 1 */
}

details {
    display: block;
}

summary {
    display: list-item;
}

template {
    display: none;
}

[hidden] {
    display: none;
}
