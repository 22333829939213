.title-container {
    min-height: calc(30vh - 94px);
    color: var(--color--primary);
    background: var(--color--gray);
    margin-top: 94px;

    @media (--media--md) {
        min-height: calc(40vh - 94px);
    }

    .headline {
        display: flex;
        align-items: center;

        h1 {
            font-size: var(--headline--lg);
            font-weight: 700;
        }
    }
}

.herobanner-page {
    width: 100vw;
    position: relative;

    @media (--media--md) {
        height: 60vh;
    }

    .text-wrapper {
        display: flex;
        justify-content: flex;
        align-items: center;
        margin: var(--spacing--md) 0;
        grid-column: large-start / large-end;

        @media (--media--md) {
            margin: 0;
            grid-column: large-start / center-start;
        }

        .text-content {
            .paragraph1 {
                p {
                    font-weight: 500;
                }
            }

            .paragraph2 {
                p {
                    font-weight: 300;
                }
            }
        }
    }

    .media-content {
        display: block;
        width: 100vw;
        height: 40vh;
        position: relative;
        grid-column: space-start / space-end;

        @media (--media--md) {
            width: 50vw;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
        }

        img {
            width: 100%;
            max-height: 100%;
            object-fit: cover;

            @media (--media--md) {
                max-height: 100%;
            }
        }
    }
}
