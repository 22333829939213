div.paragraph {
    display: block;
    width: 100%;
    hyphens: auto;
    gap: 2rem;
    columns: 1;

    @media (--media--md) {
        &.mdr\:columns-2 {
            columns: 2;
        }
    }
    @media (--media--lg) {
        &.lg\:columns-3 {
            columns: 3;
        }
        &.lg\:columns-4 {
            columns: 4;
        }
    }

    &.text--xl {
        hyphens: none;
    }

    p {
        strong {
            font-weight: 500;
        }
    }

    strong {
        font-weight: 600;
    }

    li {
        break-inside: avoid;
    }

    ul {
        list-style-type: square;
        margin-left: calc(var(--spacing--md) - 15px);

        li {
            padding: 0.25rem 0;
        }
    }

    ol {
        list-style-type: none;
        display: block;
        counter-reset: list-number;

        li {
            display: block;
            align-items: center;
            position: relative;
            padding: 0.5rem 0;
            padding-left: 2rem;
            counter-increment: list-number;

            &::before {
                content: counter(list-number);
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25px;
                height: 25px;
                position: absolute;
                top: 9px;
                left: 0;
                font-weight: 900;
                color: var(--color--font-base);
                background-color: var(--color--primary);
                border-radius: 50px;
                aspect-ratio: 1 / 1;
            }
        }
    }
    /* FOR BACKEND */
    .ck-content {
        .table {
            display: inline !important;
        }
    }
    /* FOR BACKEND */

    table {
        width: 100%;
        border-collapse: collapse;
        text-align: left;

        strong {
            white-space: nowrap;
        }

        thead {
            tr {
                border-bottom: 1px solid var(--color--font-base);

                th {
                    opacity: 0.7;
                    font-weight: 400;
                    padding: 7px 0;
                }
            }
        }

        tbody {
            td {
                vertical-align: top;
            }
            tr {
                border-bottom: 1px solid var(--color--font-base);
                td {
                    font-weight: 900;
                    padding: 0.1rem 0;
                }
            }
        }
    }
}
