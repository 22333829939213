button {
    display: flex;
    width: 100%;

    &.centered {
        justify-content: center;
    }

    &.left {
        justify-content: left;
    }

    &.right {
        justify-content: right;
    }

    a.button {
        display: inline-block;
        opacity: 1;
        font-size: var(--text--base);
        line-height: var(--text--base-height);
        font-weight: 300;
        text-decoration: none;
        transition: all 0.15s var(--easing--in-out);

        &.round {
            font-weight: 300;
            color: var(--color--font-base);
            padding: 1rem 2rem;
            border: 1px solid var(--color--font-base);
            border-radius: 30px;
            transition: all 0.15s var(--easing--in-out);

            @media (hover: hover) {
                &:hover {
                    opacity: 1;
                    color: var(--color--light);
                    background-color: var(--color--primary);
                    border: 1px solid var(--color--primary);
                }
            }
        }

        &.arrow {
            position: relative;
            color: var(--color--font-base);
            padding: 4px 0;
            border-bottom: 1px solid var(--color--line);

            @media (hover: hover) {
                &:hover {
                    opacity: 1;
                    &:after {
                        width: calc(100% + 10px);
                    }

                    &:before {
                        opacity: 1;
                        transition-delay: 0.5s;
                    }
                }
            }

            &:after {
                content: "";
                opacity: 1;
                width: 0%;
                height: 1px;
                position: absolute;
                bottom: -1px;

                left: 0;
                border-bottom: 1px solid var(--color--font-base);
                transition: all 0.5s var(--easing--in-out);
            }

            &:before {
                content: url("/_Resources/Static/Packages/LC.Site/Images/arrow-tip.svg");
                display: block;
                opacity: 0;
                width: 10px;
                height: 10px;
                position: absolute;
                top: 22px;
                right: -13px;
                transition-delay: 0s;
            }
        }
    }
}
