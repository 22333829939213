/* 1. abstracts
variables */
@import "Assets/pcss/abstracts/_config.pcss";

/* 2. vendors */

/* 3. base */
@import "Assets/pcss/base/_base.pcss";
@import "Assets/pcss/base/_font-face.pcss";
@import "Assets/pcss/base/_normalize.pcss";

/* 3. global */
@import "Assets/pcss/global/_colors.pcss";
@import "Assets/pcss/global/_cookieconsent.pcss";
@import "Assets/pcss/global/_font-size.pcss";
@import "Assets/pcss/global/_margin.pcss";
@import "Assets/pcss/global/_padding.pcss";

/* 4. themes */
@import "Assets/pcss/themes/_default.pcss";

/* 5. components */
@import "Component/Atom/AccordionItem/_accordionitem.pcss";
@import "Component/Atom/BackendNotice/_backendnotice.pcss";
@import "Component/Atom/Button/_button.pcss";
@import "Component/Atom/Headline/_headline.pcss";
@import "Component/Atom/Lead/_lead.pcss";
@import "Component/Atom/MediaCaption/_mediacaption.pcss";
@import "Component/Atom/Paragraph/_paragraph.pcss";
@import "Component/Atom/TabListItem/_tablistitem.pcss";
@import "Component/Atom/GalleryImage/_galleryimage.pcss";
@import "Component/Atom/TabItem/_tabitem.pcss";
@import "Component/Atom/TabLabel/_tablabel.pcss";

@import "Component/Molecule/Accordion/_accordion.pcss";
@import "Component/Molecule/Media/_media.pcss";
@import "Component/Molecule/Tile/_tile.pcss";
@import "Component/Molecule/Fact/_fact.pcss";
@import "Component/Molecule/Person/_person.pcss";
@import "Component/Molecule/Herobanner/_herobanner.pcss";
@import "Component/Molecule/TextMedia/_textmedia.pcss";
@import "Component/Molecule/TextMaps/_textmaps.pcss";
@import "Component/Molecule/TableMatches/_tablematches.pcss";
@import "Component/Molecule/Timeline/_timeline.pcss";
@import "Component/Molecule/Form/_form.pcss";

@import "Component/Organism/Container/_container.pcss";
@import "Component/Organism/Section/_section.pcss";
@import "Component/Page/News/_news.pcss";
@import "Component/Page/Page/_page.pcss";
@import "Component/Page/Contact/_contact.pcss";
@import "Component/Organism/Group/_group.pcss";

@import "Component/Template/Fragment/Footer/_footer.pcss";
@import "Component/Template/Fragment/Header/_header.pcss";
@import "Component/Template/Fragment/Navigation/NavigationFooter/_navigationfooter.pcss";
@import "Component/Template/Fragment/Navigation/NavigationHeader/_navigationheader.pcss";
@import "Component/Template/Fragment/Navigation/NavigationBurger/_navigationburger.pcss";

@import "../../../../../node_modules/swiper/swiper-bundle.css";
