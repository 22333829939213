section.section {
    position: relative;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;

    @media (--media--md) {
        background-attachment: none;
    }

    video.background--video {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        object-fit: cover;
        inset: 0;
    }
}
