.hamburger-icon {
    flex-direction: column;
    pointer-events: none;
    transform: scale(1);

    @media (hover: hover) {
        &:hover {
            transform: scale(1.1);
        }
    }

    span {
        display: block;
        width: 40px;
        height: 2px;
        background-color: var(--color--font-base);
        margin: 8px auto;
        border-radius: 10px;
        transition: transform 0.15s var(--easing--in-out);
    }

    &.active {
        span {
            &:nth-child(1) {
                transform: translate(0px, 5px) rotate(45deg);
            }

            &:nth-child(2) {
                transform: translate(0px, -5px) rotate(-45deg);
            }
        }
    }

    &.reverse {
        span {
            &:nth-child(1) {
                animation-direction: reverse;
            }

            &:nth-child(2) {
                animation-direction: reverse;
            }
        }
    }
}
