.tile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: var(--spacing--sm);
    border: 1px solid var(--color--line);
    border-radius: var(--radius--lg);

    .headline {
        h3 {
            font-size: var(--headline--xs);
            line-height: var(--headline--lg-height);
            font-weight: 400;
            color: var(--color--primary);
        }
    }

    .paragraph {
        font-weight: 300;

        &.text--xs {
            td {
                font-size: var(--text--xs);
            }
        }

        &.text--base {
            td {
                font-size: var(--text--base);
            }
        }

        &.text--xl {
            td {
                font-size: var(--text--xl);
            }
        }

        table {
            tr {
                border: none;

                td {
                    font-weight: 300;
                    padding-right: var(--spacing--sm);

                    strong {
                        font-weight: 500;
                    }

                    a {
                        font-weight: 300;
                    }
                }
            }
        }
    }
}
