.tab-list-item {
    display: flex;
    flex-direction: column;
    padding: var(--spacing--xs) 0;
    border-top: 1px solid var(--color--line);

    .headline {
        display: block;
        color: var(--color--primary);

        h3 {
            font-size: var(--headline--xs);
        }
    }

    .paragraph {
        display: block;
        font-weight: 300;

        p {
            font-weight: 300;
        }
    }
}
